.slider {
    position: fixed;
    top: 0;
    left: 0;
    transition: .2s ease;
    background-color: #89ba16;
    height: 4px;
    width: 40%;
    z-index: 1000;
}

.fullsize {
    position: fixed;
    left: 0;
    z-index: 10000;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.597);
    backdrop-filter: blur(10px);
    justify-content: center;
    align-items: center;
    animation: show .5s ease;
    cursor: pointer;
}

.container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    color: rgb(16, 16, 16);
    cursor: auto;
}

.mainline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.close {
    font-size: 26px;
    transition: .5s ease;
}

.close:hover {
    cursor: pointer;
    color: rgb(255, 0, 76);
    transition: .5s ease;
}

.features {
    color: rgb(167, 157, 157);
    font-size: 15px;
    font-family: 'e-Ukraine-UL';
}

.featuresContainer {
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    width: 100%;
    min-width: 450px;
}

.button {
    background-color: rgb(166, 197, 57);
    color: white;
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    transition: .2s ease;
    justify-content: center;
}

.button:hover {
    background-color: rgb(133, 166, 15);
    transition: .2s ease;
    cursor: pointer;
}

@keyframes show {
    0% {
        background-color: rgba(0, 0, 0, 0);
        backdrop-filter: blur(0px);
    }

    100% {
        background-color: rgba(0, 0, 0, 0.597);
        backdrop-filter: blur(10px);
    }
}

@media screen and (max-width: 500px) {
    .featuresContainer {
        width: 100%;
        min-width: auto;
    }
}