.container {
    z-index: 1;
    background-color: #2E2C2C;
}

.holder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    width: 100%;
    max-width: 1521px;
    margin-bottom: 90px;
    padding: 0 40px;
}

.iconContact {
    width: 50px;
    transition: .5s ease;
}

.iconContact:hover {
    transition: .2s ease;
    transform: translateY(-10px) scale(1.05);
    cursor: pointer;
}

.iconHolders {
    display: flex;
    gap: 15px;
    margin: 20px 0 0px 0;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
}

.contactsContainer {
    max-width: 1500px;
    width: 100%;
    margin: auto;
    text-align: center;
    margin-top: -150px;
}

.titleContainer {
    margin-top: 120px;
    margin-bottom: 50px;
}

.title {
    font-size: 30px;
    font-family: 'e-Ukraine Head';
    line-height: 27px;
    color: white;
    margin-bottom: 20px;
    font-weight: 700;
}

.titleContactDesc {
    font-size: 16px;
    font-family: 'e-Ukraine-L Head';
    line-height: 27px;
    color: white;
    margin-top: 20px;
    width: 90%;
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.text {
    display: flex;
    flex-wrap: wrap;
    gap: 15%;
    justify-content: center;
}

.separator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 80px 20px;
    max-width: 1400px;
    margin: auto;
}

.separator_title {
    font-size: 26px;
    max-width: 700px;
}

.separator_info {
    justify-content: end;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 5px;
}

.separator_additional {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.separator_number {
    font-size: 50px;
    color: rgb(7, 188, 12);
    font-family: 'e-Ukraine Head';
}

.separator_small {
    font-size: 14px;
    font-family: 'e-Ukraine-UL';
}

.attention {
    height: 40px;
    display: flex;
    text-transform: uppercase;
    letter-spacing: -0.05em;
    font-size: 14px;
    justify-self: start;
    justify-content: center;
    font-family: 'e-Ukraine-UL';
    background-color: rgb(164, 141, 115);
}


@media screen and (max-width: 1235px) {
    .content {
        width: 85%;
    }

    .subtitle {
        text-align: cent er;
        width: 100%;
        max-width: 600px;
        margin: auto;
    }

    .text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 80px;
    }
}

@media screen and (max-width: 1432px) {
    .title {
        text-align: center;
    }

    .text {
        justify-content: center;
    }
}

@media screen and (max-width: 1168px) {
    .separator {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 40px;
    }

    .separator_info {
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 873px) {
    .content {
        width: 85%;
    }

    .title {
        font-size: 22px;
    }
}

@media screen and (max-width: 768px) {
    .titleContainer {
        margin-bottom: 70px;
        margin-top: 70px;
    }
}

@media screen and (max-width: 555px) {
    
    .contactsContainer {
        margin-top: 0;
    }

    .iconHolders {
        padding: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}