body {
  background-color: rgb(36, 36, 36);
  color: aliceblue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'e-Ukraine-L';
}

a {
  color: inherit;
  text-decoration: none;
}

.slick-prev:before,
.slick-next:before {
  color: white !important;
  font-size: 40px !important;
  opacity: 1 !important;
  transition: .2s ease;
  border: 2px solid white;
  border-radius: 100px;
  padding: 6px 3px 0 4px;
}

.slick-prev:hover::before {
  color: rgb(166,197,57) !important;
  border: 2px solid rgb(166,197,57);
  transition: .2s ease;
}

.slick-prev {
  left: 30px !important;
  z-index: 1000;
}

.slick-next:hover::before {
  color: rgb(166,197,57) !important;
  border: 2px solid rgb(166,197,57);
  transition: .2s ease;
}

.slick-next {
  right: 45px !important;
}