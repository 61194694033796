@font-face {
  font-family: 'e-Ukraine-R';
  src: url('/assets/fonts/e-Ukraine/e-Ukraine-Regular.otf');
}

@font-face {
  font-family: 'e-Ukraine-L';
  src: url('/assets/fonts/e-Ukraine/e-Ukraine-Light.otf');
}

@font-face {
  font-family: 'e-Ukraine-UL';
  src: url('/assets/fonts/e-Ukraine/e-Ukraine-UltraLight.otf');
}

@font-face {
  font-family: 'e-Ukraine-B';
  src: url('/assets/fonts/e-Ukraine/e-Ukraine-Bold.otf');
}

@font-face {
  font-family: 'e-Ukraine Head';
  src: url('/assets/fonts/e-Ukraine/e-UkraineHead-Regular.otf');
}

@font-face {
  font-family: 'e-Ukraine-L Head';
  src: url('/assets/fonts/e-Ukraine/e-UkraineHead-Light.otf');
}

@font-face {
  font-family: 'e-Ukraine-UL Head';
  src: url('/assets/fonts/e-Ukraine/e-UkraineHead-UltraLight.otf');
}

@font-face {
  font-family: 'e-Ukraine-T Head';
  src: url('/assets/fonts/e-Ukraine/e-UkraineHead-Thin.otf');
}

.main-container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


* {
  font-family: 'e-Ukraine-L Head';
}