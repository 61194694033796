.container {
    z-index: 1;
    background-color: #2E2C2C;
}

.holder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    margin-top: 0px;
    width: 100%;
    max-width: 1521px;
    margin-bottom: 60px;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    justify-content: center;
}

.titleMain {
    font-size: 26px;
    font-family: 'e-Ukraine Head';
    max-width: 800px;
    text-align: center;
}

.helpTitle {
    top: 30px;
    left: 30px;
    padding: 10px 20px;
    background-color: rgb(255, 255, 255);
    font-family: 'e-Ukraine Head';
    color: #2E2C2C;
    position: absolute;
    transition: .2s ease;
    user-select: none;
    -webkit-user-drag: none;
}

.hide {
    opacity: 0;
    transition: .2s ease;
}

.images {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1200px;
}

.image {
    width: 1200px;
    border-radius: 8px;
    user-select: none;
    -webkit-user-drag: none;
}

.highlighted {
    position: absolute;
    opacity: 0;
    transition: .2s ease;
    user-select: none;
}

.highlightedPocket {
    position: absolute;
    user-select: none;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2e2c2c94;
    color: white;
    border-radius: 100%;
    font-size: 11px;
    transition: .3s ease;
}

.highlighted:hover {
    opacity: 1;
    transition: .2s ease;
    user-select: none;
    -webkit-user-drag: none;
}

.cardInfo {
    opacity: 0;
    transition: .2s ease;
    position: absolute;
    top: 30px;
    right: 30px;
    width: 230px;
    height: 290px;
    background-color: #ffffff;
    color: #2E2C2C;
    font-family: 'Arial';
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.cardInfoMobile {
    padding: 20px;
    display: none;
    background-color: #ffffff;
    color: #2E2C2C;
    border-radius: 8px;
    font-family: 'Arial';
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.cardHolderMobile {
    padding: 10px 60px;
    display: none;
}

.animated {
    animation: swipeme;
    animation-duration: 5s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}

.title {
    font-size: 24px;
    /* font-weight: 600; */
    margin-bottom: 20px;
    font-family: 'e-Ukraine-B';
}

.number {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: white;
    font-size: 27px;
    font-weight: 600;
    background-color: #463333;
}

.swipehelper {
    font-size: 80px;
    position: absolute;
    left: 60%;
    bottom: 50%;
    z-index: 10;
    animation: swipemeHelper;
    animation-duration: 5s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}

@keyframes swipemeHelper {
    0% {
        transform: translate(0px);
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    25% {
        transform: translate(0px);
        opacity: 1;
    }

    40% {
        transform: translateX(-40px);
        opacity: 1;
    }

    70% {
        transform: translate(0px);
        opacity: 0;
    }

    100% {
        transform: translate(0px);
        opacity: 0;
    }
}

@keyframes swipeme {
    0% {
        transform: translate(0px);
    }

    25% {
        transform: translate(0px);

    }

    30% {
        transform: translateX(-20px);
    }

    70% {
        transform: translate(0px);
    }

    100% {
        transform: translate(0px);
    }
}

.titleMobile {
    font-size: 24px;
    /* font-weight: 600; */
    margin-bottom: 20px;
    font-family: 'e-Ukraine-B';
}

.numberMobile {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: white;
    font-size: 27px;
    font-weight: 600;
    background-color: #463333;
}

.infoBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 55%;
}

.infoBlockMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 55%;
    margin: 20px 0;
}

.sellBlock {
    background-color: #f2ecec;
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.sellBlockMobile {
    background-color: #f2ecec;
    width: 100%;
    min-height: 110px;
    height: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 8px;
}

.subTitle {
    padding: 20px;
    font-size: 15px;
}

.subTitleMobile {
    padding: 20px;
}

.flats>span::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
    background-color: #948080;
}

.reserved::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #d5d5d5 !important;
    margin-right: 10px;
    vertical-align: middle;
}

.mobileHandler {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
}

.mobileHandlerArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    color: #2E2C2C;
    font-size: 20px;
    font-family: 'e-Ukraine Head';
    border-radius: 100%;
    transition: .2s ease;
}

.mobileHandlerArrow:hover {
    transition: .2s ease;
    background-color: #c0abab;
    transform: scale(0.93);
}

.additional {
    margin-top: 20px;
    color: #c0abab;
}

.flatsInfo {
    margin-top: 20px;
}

.titleAdd {
    text-align: center;
    font-size: 18px;
    margin-bottom: -10px;
    color: #2E2C2C;
    font-weight: 600;
}

.datum {
    font-size: 18px;
}

@media screen and (max-width: 1200px) {
    .image {
        width: 100%;
        border-radius: 0;
    }

    .images {
        width: 100%;
    }

    .content {
        padding: 0;
    }

    .cardInfo {
        top: 20px;
        right: 20px;
        width: 20vw;
        height: 40vh;
    }

    .subTitle {
        font-size: 0.7em;
    }

    .title {
        font-size: 1em;
    }

    .number {
        font-size: 1em;
        width: 5vw;
        height: 5vw;
    }
}

@media screen and (max-width: 768px) {
    .cardInfo {
        display: none !important;
    }

    .cardHolderMobile {
        display: flex !important;
        justify-content: center;
        flex-direction: column;
    }

    .titleMain {
        width: 90%;
        margin: auto;
    }

    .helpTitle {
        display: none;
    }

    .cardHolderMobile {
        width: calc(100% - 120px);
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .cardInfoMobile {
        width: 100%;
    }
}